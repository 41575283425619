.filter-container.category{
  
  .__react_component_tooltip.tooltip{
    padding: 0;
    margin: 0;
    border-radius: 0 5px 5px 5px;

    .category-container{
      width: 230px;
      margin: 5px 0 20px 35px;

      .title{
        border-bottom: solid 1px white;
        text-transform: uppercase;
        padding: 5px 0;
        font-weight: bold;
  
        label{
          padding-left: 15px;
        }
        label::before {
          top: 1px;
        }
        label::after {
          top: 6px;
          left: 2px;
          font-size: 0.65rem;
        }        
        .partial + label::after {
          top: -3px;
        }
      }
  
      .partial + label::after {
        content: '-';
        top: -1px;
        left: 3px;
        font-size: 1rem;
        font-weight: 400;
      }
      .partial + label>span {
        font-weight: 400;
      }
      
      ul{
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none;
        height: 250px;
        overflow: auto;
  
        li{
          position: relative;
          height: 25px;
          line-height: 15px;
          font-weight: 200;

          &.title{
            padding: 0;
            height: 20px;
            line-height: 20px;
            vertical-align: middle;
            margin-bottom: 5px;

            .subtitle{
              font-weight: 300;
            }
          }

          label{
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;
            font-size: 0.9rem;
            height: 25px;
            
            &:hover{
              text-decoration: none;
            }
          }

          .category-only,
          .category-value{
            margin-right: 5px;
          }      
          .category-only{
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
            text-transform: uppercase;
            font-size: 0.75rem;
            background-color: #e43e21;
            cursor: pointer;
          }
          label:hover + .category-only,
          .category-only:hover{
            display: block;
            font-weight: 500;
            text-decoration: underline;
          }
        }
      }      
    }

    .global-potential{
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      text-align: center;
      width: 220px;
      margin: 0 0 0 40px;
      border: solid 0.2rem;
      border-radius: 0 5px 5px 0;
      color: #e43e21;
      background-color: rgba(255, 255, 255, 0.8);

      p{
        font-size: 1.14rem;
        font-weight: 200;
        margin: 0 0 20px 0;
      }

      span{
        font-weight: bold;
        font-size: 1.5rem;
      }

      .confirm-container{        
        justify-content: center;        

        button{
          border: 0;
        }      
        .reset{
          color: #e43e21;
        }      
        .confirm{
          background-color: #e43e21;
        }
      } 
    }
  }
}