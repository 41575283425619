.table-barometer
{ 
  &.gsc-keywords
  {
    /** Table DOM element for thematiques view **/  
    .table-header{
      thead
      {
        /** Children titles header **/
        tr:nth-child(2){
          th{
            &.col-label{
              .column-header-container{
                #filter-label::after{
                  left: 40%;
                }
              }
            }
            &.col-gsc{
              .column-header-container{
                #filter-gsc\.position::after{
                  left: 50%;
                }
              }
            }
            &.col-decisions{
              .column-header-container{
                #filter-action::after{
                  left: 50%;
                }
              }
            }
          }
        }
      }
    }
        
    /*****************/
    /** Start Cells **/
    /*****************/
    .categories-editor-container
    {
      position: relative;     
      display: flex;
      flex-flow: column;
      align-items: center;
      min-height: 50px;

      .categories-editor
      {
        display: flex;
        flex-flow: row wrap;
        width: 480px;
        min-height: 50px;

        .category-editor
        {
          padding: 0 5px;
          display: flex;
          flex-flow: column wrap;
          align-items: center;
          justify-content: center;

          .react-select{  
            width: 150px;

            .react-select__menu
            {
              .react-select__option:first-child{
                background-color: #ccc;
                font-weight: bold;
              }
            }
          }

          .free-field{
            width: 142px;
            margin-top: 5px;
          }
        }
      }      

      input[type="button"]
      {
        cursor: pointer;
        height: 20px;
        margin-top: 5px;
        width: 250px;
        color: white;
        background-color: #e43e21;
        border: solid 1px white;
        border-radius: 5px;
      }
      
      .hide{
        display: none;
      }
    }
    /*****************/
    /** End Cells **/
    /*****************/
  }
}