.filter-container.period{

  .__react_component_tooltip.tooltip{
    flex-flow: column wrap;
    margin: 0;
    width: 920px;
    
    border-radius: 5px 0 5px 5px;

    .calendar-container{
      height: 220px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      position: relative;
      border-bottom: solid 1px white;
      padding-bottom: 20px;

      .ant-picker-focused{
        box-shadow: none;
        border: none;
      }

      .ant-picker{
        width: 700px;
        background-color: transparent;
        border: none;

        &.ant-picker-range{
          display: flex;
          flex-flow: row;
          align-items: flex-end;
          justify-content: center;
        }
        .ant-picker-input{
          width: auto;
          
          > input{
            color: rgba(255, 255, 255, 0.7);
            width: 70px;
            text-align: center;

            &::placeholder{
              color: rgba(255, 255, 255, 0.7);
            }
          }          
        }

        .ant-picker-range-separator{
          color: rgba(255, 255, 255, 0.7);
          height: 22px;
          line-height: 22px;
          font-weight: bold;
        }

        .ant-picker-active-bar,
        .ant-picker-suffix{
          display: none;
        }

        .ant-picker-dropdown{
          top: 0!important;
          left: 0!important;
          width: 690px;
          padding: 20px 10px;
          background-color: transparent;

          .ant-picker-range-arrow{
            display: none;
          }
          
          .ant-picker-range-wrapper{
            justify-content: center;

            .ant-picker-panel-container{
              background-color: transparent;
              border: none;
              box-shadow: none;
              color: white;
  
              .ant-picker-panels{
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
    
                .ant-picker-panel{   
                  width: 290px;           
                  text-align: center;
                  border: none;
    
                  .ant-picker-month-panel{
                    width: 300px;
                  }

                  .ant-picker-header{
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    border: none;
    
                    .ant-picker-year-btn{
                      width: 255px;
                      height: 24px;
                      line-height: 24px;
                      color: white;
                      font-size: 0.9rem;                  
                      border-radius: 5px;
                      border: solid 1px white;
                    }
    
                    .ant-picker-header-view{
                      line-height: 24px;
                    }

                    .ant-picker-header-super-prev-btn,
                    .ant-picker-header-super-next-btn{
                      width: 10px;
                      height: 24px;
                    }
                    .ant-picker-header-super-next-btn{
                      margin-left: 5px;
                    }
    
                    .ant-picker-super-prev-icon,
                    .ant-picker-super-next-icon{
                      display: block;
                      position: relative;
                      width: 10px;
                      height: 21px;
                      transform: rotate(-45deg);
                    }
                    .ant-picker-super-next-icon{
                      transform: rotate(135deg);
                    }
                    .ant-picker-super-prev-icon:after,
                    .ant-picker-super-next-icon:after{
                      display: none;
                    }
                    .ant-picker-super-prev-icon:before,
                    .ant-picker-super-next-icon:before{
                      position: absolute;
                      top: 6px;
                      left: 0;
                      display: inline-block;
                      width: 12px;
                      height: 12px;
                      border: 0 solid white;
                      border-radius: 2px;
                      border-width: 2px 0 0 2px;
                      content: "";
                    }    
                  }

                  .ant-picker-content{
                    height: 120px;

                    .ant-picker-cell {
                      padding: 3px 0;
                      color: white;
                      cursor: pointer;
                    }
                    .ant-picker-cell::before {
                      position: absolute;
                      top: 50%;
                      right: 0;
                      left: 0;
                      z-index: 1;
                      height: 24px;
                      transform: translateY(-50%);
                      transition: all 0.3s;
                      content: '';
                    }
                    .ant-picker-cell .ant-picker-cell-inner {
                      position: relative;
                      z-index: 2;
                      display: inline-block;
                      min-width: 24px;
                      height: 24px;
                      line-height: 24px;
                      border-radius: 2px;
                      transition: background 0.3s, border 0.3s;
                    }
                    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
                    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
                      color: black;
                      background: white;
                      opacity: 0.5;
                    }
                    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before{
                      background-color: rgba(255, 255, 255, 0.5);
                    }
                    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
                    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
                      background-color: rgba(255, 255, 255, 0.5);
                    }
                    .ant-picker-cell-in-view.ant-picker-cell-in-range::before{
                      background-color: rgba(255, 255, 255, 0.5);
                    }
                    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
                    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
                    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
                      background-color: rgba(255, 255, 255, 0.5);
                    }
                    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
                    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
                    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
                    .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
                    .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
                    .ant-picker-month-panel .ant-picker-cell-range-hover-start::after,
                    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
                    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
                    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
                      border-left: 1px dashed rgba(255, 255, 255, 0.5);
                    } 
                    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
                    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
                    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
                    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
                    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
                    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after,
                    .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
                    .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
                    .ant-picker-month-panel .ant-picker-cell-range-hover-end::after  {
                      border-right: 1px dashed rgba(255, 255, 255, 0.5);
                    }
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
                    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
                      border-top: 1px dashed rgba(255, 255, 255, 0.5);
                      border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
                    }
                    .ant-picker-cell-disabled::before {
                      background: rgba(0, 0, 0, 0.1);
                    }
                  }
                }
                .ant-picker-panel:first-child .ant-picker-header-super-next-btn{ 
                  display: none;
                }
                .ant-picker-panel:last-child .ant-picker-header-super-prev-btn{ 
                  display: none;                  
                }
              }
            }
          }          
        }
      }

      .period-filters{
        h4{
          margin-left: 10px;
          text-transform: uppercase;
          font-size: 0.85rem;
          font-weight: bold;
        }
        .preselect{
          button{
            width: 100%;
            font-size: 0.9rem;
            text-transform: none;
          }
        }
        .date-select{
          select{
            width: 100px;
            height: 27px;
            border-radius: 5px;
            border: none;

            &:first-child{
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}