.header-filter-container
{
  h3{
    padding-left: 3px;
  }

  .react-select{
    height: 24px;
    width: 250px;
    background-color: transparent;
    border-radius: 0;
    
    .react-select__control{
      background-color: transparent;
      border-radius: 0;
      
      .react-select__value-container{
        .react-select__placeholder,
        .react-select__single-value{
          color: white;
          font-size: 1.5rem;
        }

        &.react-select__value-container--has-value,
        &.react-select__value-container--has-value + div{
          border-radius: 0;
        }
      }
    }
    .react-select__indicators{
      height: 24px;
      margin-left: 20px;
    }
    .react-select__menu{
      width: 250px;
      font-size: 1rem;
      font-weight: 400;
      white-space: nowrap;
    }
  }   
}