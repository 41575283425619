.left-filter-wrapper{
  text-align: right;
  position: relative;
  padding: 15px;
  width: 250px;
  height: calc(100vh);
  background-color: #fad8d3;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
 
  ul{
    padding: 0;

    li{
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      width: 250px;
      list-style: none;
      padding: 10px 0;

      a{
        height: 25px;
        line-height: 25px;
        padding: 5px;
        text-align: left;
        font-weight: bold;
        color: black;        
        background-color: rgba(177, 177, 177, 0.2);
      }
      .text-link{
        width: 205px;
        border-radius: 10px 0 0 10px;
      }
      .picto-link{
        text-align: center;
        width: 25px;
        border-radius: 0 10px 10px 0;
      }

      &:hover{        
        a{
          background-color: rgba(248, 248, 248, 0.5);
        }
        .picto-link svg{
          fill: #e43e21
        }
      }
    }
  }

  /** Toggler to open/close left nav **/
  .toggler{
    width: auto;
    height: 30px;
    margin-right: 5px;
    text-align: right;
    cursor: pointer;
    
    svg{
      fill: black;
      stroke: black;
    }
  }
}