.select-groups{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  
  h4{
    width: 103px;
    height: 20px;
    font-size: 0.65rem !important;
    border: solid 1px white;
    border-radius: 5px 0 0 5px;
    padding: 1px 0 0 5px;
  }

  // case without group column
  &.without-group{
    h4{
      border: none;
      font-size: 0.7rem !important;
      padding: 0;
    }
  }

  span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    line-height: 10px;
    padding: 0 6px;
    background-color: black;
    font-size: 0.72rem;
    color: white;    
    border-radius: 5px;
    z-index: 1;
  }

  // Select for select all entry
  .react-select
  {
    width: 110px;
    height: 21px;
    background-color: white;
    border-radius: 0 5px 5px 0;
    border: solid 1px white;
    box-sizing: content-box;
    text-align: center;
        
    .react-select__control{
      .react-select__value-container{

        &.react-select__value-container--has-value,
        &.react-select__value-container--has-value + div{
          background-color: white;
          color: black;
        }
        &.react-select__value-container--has-value + div {
          svg{
            fill: black;
          }
        }
        
        .react-select__input-container{
          .react-select__input{
            height: 20px;
          }
        }
        .react-select__placeholder,
        .react-select__single-value{
          color: black;
          font-size: 0.86rem;
          font-weight: 900;
          padding-left: 15px;
        }
      }
    }
    .react-select__indicators{
      height: 20px;
      
      .react-select__indicator{
        svg{
          fill: black;
        }
      }
    }
    .react-select__menu{
      .react-select__option{
        color: black;
      }
    }
  }
}