.chart-container{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 500px;
  height: auto;
  border: solid 0.5px #988c8c;
  border-radius: 5px;
  background-color: white;

  .fullscreen{
    margin-top: 20px;
    background: white;
  }

  .dataviz-tabs-container{
    border-radius: 5px;
    display: flex;
    flex-flow: row; 
    background-color: #f5f2f2;

    .dataviz-tab{
      flex-grow: 4;  
      width: auto;
      height: 90px;
      padding: 11px 22px 0 22px;
      border-right: solid 0.5px #988c8c;
      border-bottom: solid 0.5px #988c8c;
      box-sizing: border-box;
      cursor: pointer;

      .title{
        margin: 0 0 10px 0;
        font-size: 0.85rem;
        color: black;
    
        h4{
          margin: 0;
          font-size: 1rem;
          font-weight: 900;
          text-transform: uppercase;
        }
      }

      .values-container{
        width: max-content;
        display: flex;
        flex-flow: row;
        font-size: 1.5rem;
        font-weight: 300;

        .values-parts-container{
          display: flex;
          flex-flow: column;
          margin-right: 10px;

          .values-part{
            display: flex;
            flex-flow: row;
            align-items: center;
  
            h5{
              margin: 0;
              font-size: 0.57rem;
              font-weight: 300;
              text-transform: uppercase;
            }

            svg{
              margin-right: 5px;
            }
  
            .value{
              font-size: 1.42rem;
              font-weight: 300;
            }
  
            .variation{
              font-size: 1rem;
              font-weight: 200;
            }

            .percent{
              font-size: 1rem;
              font-weight: 200;
            }
            
            &.top{
              border-bottom: solid 0.25px #979797;
              padding-bottom: 2px;
              padding-right: 10px;
            }
            &.bottom{
              justify-content: center;             
            }
          }

          &:first-child{
            .bottom{
              .value{
                font-weight: bold;
              }
            }
          }
        }
      }

      // Volume tab
      &.volume{
        border-radius: 5px 0 0 0; 
      }

      // GSC tab
      &.gsc{
        border-radius: 0 5px 0 0;
        border-right: none;
      }

      // Selected tab
      &.on{
        background-color: white;
        border-bottom: none;
      }
    }
  }

  /** START options dataviz **/
  .dataviz-options-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: white;
    
    .dataviz-option{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px;

      // radio option toggler
      &.radio{
        button{
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: flex-start;
          width: 28px;
          height: 16px;
          border: solid 1px #e43e21;
          border-radius: 8px; 
          
          &.on{
            justify-content: flex-end;
          }
        }
        span{
          display: block;
          border-radius: 50%;
          background-color: #e43e21;
          width: 14px;
          height: 14px;
        }
      }

      // checkbox option toggler
      &.checkbox{
        position: relative;
        flex-flow: column;
        justify-content: flex-start;
        padding-left: 10px;
        margin-right: 5px;
        
        label{
          text-decoration: none;
        }
        label::before{
          width: 10px;
          height: 10px;     
          top: -3px;
          left: -8px;          
          border-radius: 2px;
          border: solid 5px;
        }
        label::after{
          content: '';
        }

        .only{
          position: absolute;
          height: 20px;
          top: 18px;
          right: 10px;
          font-size: 0.7rem;
          color: #e43e21;
          text-transform: uppercase;
          opacity: 0;

          &:hover{
            opacity: 1;
          }
        }
        &:hover{
          .only{
            opacity: 1;
          }
        }

        label::before{
          border-color: var(--borderColor);
          background-color: white;          
        }

        label.on::before{
          background-color: var(--backgroundColor);
        }

        label.disable::before{
          background-color: var(--borderColor);
        }
      }

      // button option toggler
      &.button{
        button{
          width: auto;
          height: 17px;
          padding: 0 10px;
          margin: 0 5px;
          border-radius: 8px;
          border: 1px solid #E43E21;
          font-weight: 300;
          font-size: 0.85rem;
          color: #E43E21;
          text-transform: uppercase;

          &.on{
            background-color: #e43e21;
            color: white;
          }
        }
      }

      // common options toggler
      &.radio,
      &.checkbox{
        label{
          font-size: 0.85rem;
          font-weight: 300;
          color: #e43e21;
          text-transform: uppercase;
          margin-right: 10px;
          cursor: pointer;

          &.disable{
            cursor: default;
          }
        }
      }
    }

    .separator{
      width: 1px;
      height: 40px;
      margin-right: 20px;
      background-color: #e9e4e3;
    }
  }
  /** END options dataviz **/

  .dataviz-container{
    border-radius: 5px;
    padding-left: 20px;

    .dataviz-chart{
      padding-bottom: 10px;

      /** Tooltip **/
      .tooltip{
        display: flex;
        flex-flow: row wrap;
        width: 610px;
        height: 223px;
        border-radius: 5px;
        font-size: 0.85rem;
        font-weight: 200;
        padding: 10px 10px 30px 10px;
        border: solid 1px #e43e21;
        
        &.red{
          background-color: #e43e21;
          color: white;
        }

        &.grey{
          background-color: #f0f0f0;
          color: black;
        }

        .tooltip-container{
          width: 100%;          
          
          h4{
            height: 20px;
            margin: 0;
            text-align: left;
            font-family: Roboto;
            font-size: 0.82rem;
            font-weight: 900;
            color: black;
            text-transform: uppercase;
            border-bottom: solid 0.5px black;          
  
            svg{
              margin-right: 5px;            
            }
          }
        }
      }

      &.perf .tooltip.visibility{
        padding-top: 0;
        height: 233px;

        .tooltip-container{
          display: flex;
          flex-flow: column;
        }
      }

      &.volume .tooltip,
      &.gsc .tooltip,
      &.perf .tooltip.position{
        width: auto;
        height: auto;
        padding-bottom: 10px;
        
        ul{
          margin-bottom: 0;
          
          li{
            height: 20px;
            font-weight: 400;
            
            hr{
              width: 18px;
              height: 2px;
              margin: 0 10px 0 0;
              border: none;              

              &.dash{
                background: linear-gradient(to right, transparent 60%, #f0f0f0 40%);
                background-size: 5px;
              }
            }

            .label{
              width: 85px;
              font-weight: 300;
              text-transform: uppercase;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .arrow{
              font-size: 0.7rem;              
              font-weight: bold;
              line-height: 1rem;
              margin-right: 3px;

              &.up{
                color: #61B458;
              }
              &.down{
                color: #e43e21;
                transform: rotate( 180deg );
              }
            }
          }
        }

        .tooltip-container{
          display: flex;
          flex-flow: row;

          ul:nth-child(2),
          ul:nth-child(3){
            border-left: solid 0.25px #979797;
            margin-left: 10px;
            padding-left: 10px;
          }
        }
      }
    }
  }

  .loader-container{
    border-radius: 5px;
    position: absolute;
    height: 100%;
    z-index: 7;
  }
}