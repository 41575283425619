.filter-container.context{

  .__react_component_tooltip.tooltip{
    margin: 0;
    width: 350px;
    border-radius: 0 5px 5px 5px;

    .filter{
      width: 350px;
      
      .title{
        padding: 0 10px;      
        text-align: left;
        font-family: 'PlayfairDisplay';
        white-space: pre-line;

        h4{
          margin: 5px 0;
        }
      }

      .separator{
        width: 100px;
        height: 1px;
        border: none;
        background-color: white;
        margin: 0 0 10px 10px;
      }

      .preselect{
        button{
          width: 100%;
          font-size: 0.9rem;
          text-transform: uppercase;
        }
      }
    }
  }
}