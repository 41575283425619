.tooltip{
  .filter{
    width: 250px;
    color: white;
    
    .title{
      text-align: left;
      font-family: 'PlayfairDisplay';
      white-space: pre-line;
      
      h4{
        margin: 10px 0;
      }
    }

    &.columns{
      font-size: 0.92rem;
      line-height: 20px;
      
      ul{
        margin: 0;
        padding-left: 0;

        li{
          list-style: none;

          >ul {
            padding-left: 20px;
          }

          ul{
            li{
              position: relative;
            }
          }

          label{
            display: block;
            width: 100%;
          }
          label:hover{
            text-decoration: none;
          }
          label::before {
            top: 5px;
          }
          label::after {
            top: 9px;
            left: 2px;            
          }
          .partial + label::after {
            content: '_';
            top: -5px;
            font-size: 1rem;
          }

          .column-only{
            position: absolute;
            top: 3px;
            right: 0;
            visibility: hidden;
            font-size: 0.8rem;
            font-weight: 500;
            text-transform: uppercase;
            text-decoration: underline;
            color: white;
          }
          .column-only:hover,
          label:hover + .column-only{
            visibility: visible;
          }
        }
      }
      
    }
  }
}