.dataviz-chart-container,
.table-container
{
  margin: 20px 20px 20px 90px;

  .options-chart-container{
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .update-infos p{
      padding-left: 5px;
      font-size: 0.85rem;
      color: #9f9f9f;

      span{
        font-weight: 500;
      }
    }
  }

  .export-options-container{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 5px;
    
    button.fullscreen,
    button.column-filter,
    button.download{
      border: none;
      background-color: transparent;
      margin-right: 10px;
  
      svg{
        fill: #e43e21;
      }
    }

    /** Tooltip **/
    #column-filter,
    #download-table{
      position: absolute;
      z-index: 2;
    }
    #column-filter::after{
      left: 219px;
    }
    #download-table::after{
      left: 185px;
    }

    #download-table{
      flex-flow: column;
      justify-content: center;
      width: 160px;
            
      hr{
        border: none;
        border-top: solid 1px white;
        color: white;
        size: 1px;
        width: 100%;
      }

      .all-results,
      .filter-results{
        margin: 5px;
        width: 150px;
      }

      .data-link-download{
        width: 50px;
        height: 25px;
        border: solid 1px;
        text-align: center;
        padding-left: 0;
      }
    }    
  }
}

.table-container{
  margin-bottom: 300px;
}

.confirm-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin: 12px 0 0 0;  

  button{
    background-color: transparent;
    border: 0;
  }

  .reset{
    font-weight: 200;
    color: white;
    
    &:hover{
      text-decoration: underline;
    }
  }

  .confirm{
    width: 90px;
    line-height: 21px;
    border-radius: 5px;
    border: solid 1px white;
    color: white;
    font-weight: bold;
    margin: 0 3px 0 10px;
  }
} 

.top-docker
{
  /** Filters menu **/
  .filter-container
  {
    h4{
      margin: 0;
    }
    
    button.filter{
      position: relative;
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      width: 230px;
      height: 60px;
      border-radius: 5px;
      background-color: white;
      border: none;
      padding: 12px 0 0 20px;
  
      .title,
      .value,
      .compare-value{
        line-height: 13px;
        width: fit-content;
      }
      .title{
        font-size: 0.8rem;
        font-weight: 200;
        text-transform: uppercase;
        color: black;
      }
      .value,
      .compare-value{
        font-size: 0.85rem;
        font-weight: bold;
        color: #e43e21;
      }
      .compare-value{
        font-size: 0.75rem;
        font-weight: 300;        
        font-style: italic;
      }
      .arrow{      
        position: absolute;
        right: 10px;
        top: 12px;
  
        svg{
          fill: #626262;
        }
      }
  
      &.open{
        background-color: #e43e21;
        border-radius: 5px 5px 0 0;
        
        .title,
        .value,
        .compare-value{
          color: rgba(255, 255, 255, 0.7);
        }
        .arrow svg{
          fill: white;
        }
      }

      &.no-options{
        cursor: default;
      }
    }
  }
}

/** Tooltip **/
.column-header-container .tooltip,
.top-docker .tooltip,
.chart-container .tooltip,
.action-container .expectedUrl .tooltip{
  position: absolute;
  z-index: 7;
      
  tr{
    height: 10px;

    td{
      padding-right: 20px;
      border: none;
    }
  }

  h4{
    color: white;
    font-size: 1rem;
    font-weight: normal;
    margin: 10px 0 15px 0;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;

    li{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

/** Menu of range or action or context filter **/
.preselect{
  li{
    height: 35px;
  }

  button{
    font-size: 1rem;        
    width: 190px;
    height: 30px;
    font-weight: 400;
    color: white;  
    text-align: left;
    padding-left: 10px;
    border-radius: 5px;

    &:hover,
    &.on{
      background-color: rgba( 255, 255, 255, 0.54);
    }
  }    
}