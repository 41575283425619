section{
  .dashboard-container.login
  {
    margin: 0 auto;
    height: 60vh;

    form{
      .row{
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 30px;

        label{
          width: 100px;
        }
        input{
          width: 200px;
        }
      }
    }

    button{
      border: solid 1px white;
      border-radius: 5px;
      background-color: #e43e21;
      color: white;
      padding: 2px 5px;
    }

    .message{
      border: solid 1px #e43e21;
      border-radius: 5px;
      background-color: white;
      color: #e43e21;
      padding: 5px 10px;
      margin-top: 10px;
      text-align: center;
    }
  }

  .top-docker.login{
    .top-docker-title{
      margin: 0 auto;
    }
  }
}