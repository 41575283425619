.loader-container
{
  position: fixed;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;  
  width: 100%;
  height: 100vh;
  z-index: 10;
  
  ul{
    margin: 20px 0;
    padding: 0;
    text-align: center;
    z-index: 9;

    li{
      list-style: none;
      color: #a29090;
      font-weight: 300;
      font-size: 1.14rem;

      &.author{
        text-align: right;
        font-family: 'SavoyeLETPlain';
        font-size: 1.14rem;
        color: #e43e21;
      }
    }
  }

  .indicator{
    margin-bottom: 20px;
    
    svg circle{
      stroke: white;
      stroke-width: 3px;      
      stroke-opacity: 1;
    }
    svg path{
      stroke-width: 4px;
    }
  }

  .loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(247, 241, 240, 0.95);
    z-index: 8;
  }

  &.hide{
    display: none;
  }
}