/** Roboto **/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
}

/** Playfair_Display **/
@font-face {
  font-family: "PlayfairDisplay";
  src: url("../fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
}

/** LucidaGrande **/
@font-face {
  font-family: "LucidaGrande";
  src: url("../fonts/lucida-grande/LucidaGrande.ttf") format("truetype");
  font-weight: normal;
}

/** Savoye_LET_Plain **/
@font-face {
  font-family: "SavoyeLETPlain";
  src: url("../fonts/Savoye_LET_Plain/Savoye_LET_Plain.ttf") format("truetype");
  font-weight: normal;
}