.table-barometer{
  
  /** Sticky Element **/
  .sticky-container{
    position: relative;
    z-index: 1;
  
    .sticky{      
      margin-top: 105px;
      padding-top: 5px;
      background-color: #f5f2f2;

      .table-header{
        margin-bottom: 0;
      }
    }
  }

  /** Table DOM element **/
  table{    
    width: 100%;
    margin-bottom: 20px;
    border: solid 0.5px #988c8c;
    border-collapse: separate;
    border-spacing: 0;

    &.table-header{
      height: 160px;
      border-radius: 5px 5px 0 0;
      border-bottom: none;

      thead{
        box-shadow: 0 3px 5px rgba(126, 120, 120, 0.5);
        
        tr{
          position: relative;
          height: 30px;
          background-color: #e5e0df;
          text-align: left;
          
          th{
            border-left: solid 0.5px #988c8c;
            font-weight: normal;          

            &:first-child{
              padding-left: 40px;
            }

            &.col-snippets,
            &.col-perfs,
            &.col-gsc,
            &.col-decisions,
            &.col-categories-editor
            {
              padding-left: 10px;
            }

            /** Header col style **/
            .column-header-container{
              display: flex;
              flex-flow: row wrap;
              align-items: center;
              position: relative;
              margin-bottom: 5px;
              
              .column-header-title{
                display: flex;
                flex-flow: row wrap;
                align-items: center;

                h4{                
                  font-weight: 900;
                  text-transform: uppercase;
                  margin: 0;
                }
              }
            }
            
            &.column-header-myperf{
              .column-header-container{
                justify-content: center;
              }
            }
          }

          th.col-label,
          th.col-volume,
          th.col-identifier-date,
          th.col-intention,        
          th.col-page-type,
          th.col-gsc ~ .col-gsc,
          th.col-snippets ~ .col-snippets,
          th.col-perfs ~ .col-perfs,
          th.col-decisions ~ .col-decisions{
            border-left: none;
            font-size: 0.93rem;
            font-weight: bold;
          }
        }

        /** Parents titles header **/
        tr:first-child{
          height: 50px;
          
          th{
            padding-left: 10px;
            
            &:first-child{
              border-left: none;
              border-radius: 5px 0 0 0;
              padding-left: 20px;
            }

            &:last-child{
              border-radius: 0 5px 0 0;
            }

            .column-header-container{
              
              h4{
                font-size: 0.86rem;
              }
              
              .keyword-container
              {
                display: flex;
                flex-direction: row;
                align-items: center;

                // checkbox mass action
                .action-checkbox[type=checkbox] + label{
                  z-index: 1;
                  height: 15px;
                  line-height: 15px;       
                  color: #e43e21;
                  font-size: 0.64rem;
                  font-weight: 900;
                  text-decoration: none;
                  text-transform: uppercase;
                } 
                // checkbox
                .action-checkbox[type=checkbox] + label::before {
                  border: 1px solid #e43e21;
                  background-color: white;
                  margin-left: 3px;
                  top: 0;
                }
                // mark
                .action-checkbox[type=checkbox]:checked + label::after {
                  top: 7.5px;
                  left: 6.5px;
                  font-weight: bold;
                  font-size: 0.85rem;
                }

                // Select for select all entry
                .react-select{
                  position: absolute;
                  width: 120px;
                  padding-left: 20px;
                  background-color: transparent;
                  border: none;
                      
                  .react-select__control{
                    height: 20px;
                    min-height: 20px;
                    background-color: transparent;
                                      
                    .react-select__value-container{
                      .react-select__input-container{
                        .react-select__input{
                          height: 20px;
                        }
                      }
                    }
                  }
                  .react-select__indicators{
                    height: 20px;
                  }
                  .react-select__menu{
                    .react-select__option{
                      color: black;
                    }
                  }
                }
              }  
            }
          }        
        }
        
        /** Children titles header **/
        tr:nth-child(2){
          th{
            padding-right: 10px;

            .column-header-container{
              display: flex;
              flex-flow: row;
              justify-content: space-between;
              width: 100%;
              height: 20px;
              border-bottom: solid 0.3px #979797;

              &.pointer{
                cursor: pointer;
              }
              
              .column-header-title{
                justify-content: flex-start;
                font-size: 0.58rem;
                font-weight: 300;
                text-transform: uppercase;

                h4{
                  font-weight: 300;
                }

                svg{
                  margin-right: 3px;
                }
              }

              .sort-filter-toggler{
                width: 5px;
                text-align: right;
                
                svg{
                  fill: #e43e21;
                }                
              }
            }

            &.col-label{
              .column-header-container{
                #filter-label::after{
                  left: 49%;
                }
              }
            }

            &.col-snippets{
              .column-header-container{
                .column-header-title{
                  svg{
                    height: 20px;
                  }
                }
                #filter-snippetsSEO::after{
                  left: 47%;
                }
              }
            }

            &.col-perfs{
              .column-header-container{
                .column-header-title{
                  h4:nth-child(2){
                    font-weight: bold;
                  }
                  h4:nth-child(3){
                    text-transform: initial;
                  }
                }
                .column-header-filter{
                  margin-left: 5px;         
                }
              }
            }

            &.col-gsc{
              .column-header-container{
                #filter-position::after{
                  left: 65%;
                }
              }
            }

            &.col-decisions{
              .column-header-container{
                #filter-action::after{
                  left: 97%;
                }
                #filter-kdifficulty::after{
                  left: 77%;
                }
              }
            }

            &.serp-viewer{
              .column-header-container{
                border-bottom: none;
              }            
            }
          }        
        }

        /** Header reminder row **/
        tr:nth-child(3)
        {
          height: 25px;
          
          th{

            &:first-child{
              border: none;

              .column-header-container{
                padding-left: 0;
              }
            }
            
            .column-header-container
            {
              min-height: 25px;
              padding-left: 5px;

              .column-header-reminder{
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                font-size: 0.57rem;
                font-weight: 300;
                border-radius: 8px;
                background-color: white;
                padding: 2px 2px 2px 5px;
                margin: 2px 2px 2px 0;
                max-width: 95px;
                
                .label{
                  text-transform: uppercase;
                  margin-right: 5px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;              

                  svg{
                    margin-right: 3px;
                    color: #e43e21;
                  }
                }

                button{
                  width: 18px;
                  color: #e43e21;
                }
    
                &.picto{
                  padding-left: 4px;
                  margin-right: 5px;
                }
              }

              .more-filter{
                font-size: 0.57rem;
                font-weight: 300;
                color: #e43e21;
                margin-left: 5px;
              }

              .more-filter-tooltip{
                .filter-container{
                  flex-flow: column;
                  align-items: center;
                  
                  .filter{
                    margin-left: 0;
                  }

                  .column-header-reminder{
                    width: 140px;
                    margin: 5px 0;
                    color: black;
                  }
                }
                .confirm-container{
                  flex-flow: column;
                  align-items: center;
                  border-top: none;
                  margin-top: 0;
                  margin-bottom: 8px;

                  .confirm{
                    width: 150px;
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        /** TOTAL row **/
        tr.totalrow{
          background-color: #f5f2f2;
          height: 30px;
          
          th{
            vertical-align: top;
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 10px;

            div{
              text-align: right;
            }
            .variation,
            .percent{            
              font-weight: 500;
              color: #9f9f9f;
            }
            .variation{
              font-size: 0.72rem;
            }
            .percent{
              font-size: 0.78rem;
            }

            &:first-child{
              border-left: none;
            }
    
            &.col-volume{
              padding-right: 10px;
            }
            &.col-snippets,
            &.col-perfs,
            &.col-gsc,
            &.col-decisions
            {
              padding-right: 10px;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &.table-body{
      border-top: none;

      tbody{               
        background-color: white;
        border-radius: 0 0 5px 5px;
          
        tr{
          &:nth-child( even ) {
            background: #f5f2f2;

            .line-container{
              .negative-line{
                background-color: #cdcbcb;
              }
            }
          }

          &:first-child{
            td{
              padding-top: 20px;              
            }
          }
          &:last-child{
            td{
              &:first-child{
                border-radius: 0 0 0 5px;
              }
              &:last-child{
                border-radius: 0 0 5px 0;
              }
            }
          }

          td{
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: solid 1px #efefef;
            border-left: solid 0.5px #988c8c;
            border-right: none;
            border-bottom: none;
            font-weight: 300;
            vertical-align: top;

            &:first-child{
              padding-left: 20px;
            }
            &:last-child{
              padding-right: 10px;
              padding-left: 10px;
            }
            
            &.col-volume{
              padding-right: 10px;
            }
            
            &.col-snippets,
            &.col-perfs,
            &.col-gsc,
            &.col-decisions
            {
              padding-right: 10px;
              padding-left: 10px;
            }
            &.serp-viewer{
              padding-right: 0;
              padding-left: 2px;
            }
            
            &.col-label,
            &.col-volume,
            &.col-identifier-date,
            &.col-intention,        
            &.col-page-type,
            &.col-gsc ~ .col-gsc,
            &.col-snippets ~ .col-snippets,
            &.col-perfs ~ .col-perfs,
            &.col-decisions ~ .col-decisions
            {
              border-left: none;
            }

            &.noresult{
              border: none;
            }
          }
        }
      }
    }
  }

  /*****************/
  /** Start Cells **/
  /*****************/
  
  /** Intentions / Page Types **/
  .intention-container,
  .page-type-container{
    position: relative;
    padding: 0 10px;
    
    .loader-container{
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;

      .indicator{
        margin: 2px 0 0 0;
        height: 17px;
      }

      .loader{
        left: auto;
        width: 100%;
        border-radius: 15px 15px;
      }
    }

    .react-select{
      width: 100%;

      .react-select__value-container{
        height: 17px;
        display: flex;
        flex-flow: row;

        .react-select__option{
          width: 100%;
          display: flex;
          flex-flow: row;
          align-items: center;
        }

        .react-select__input-container{
          width: 0;
        }

        .picto{
          display: flex;
          flex-flow: row;
          align-items: center;
        }
      }

      .react-select__option{
        display: flex;
        flex-flow: row;
        align-items: center;
        text-align: left;

        svg{
          margin-left: 2px;
        }
      }
    }

    .tooltip{
      width: 300px;
      height: 140px;
      position: absolute;
      z-index: 1;
      
      .tooltip-container{
        width: 100%;
        height: 140px;
        overflow-y: scroll;

        h4{
          width: 95%;
          height: 20px;
          text-align: left;
          text-transform: uppercase;
          border-bottom: solid 0.5px black;
        }

        .loader-container{
          position: absolute;
          width: 100%;
          height: 100%;
        }

        &.history
        {
          ul{
            padding: 0;
            margin: 0 10px 0 0;
            text-align: left;

            li{
              display: flex;
              flex-flow: row;
              align-items: center;
              justify-content: flex-start;            
              padding: 2px 0;  
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  /** Volume / SERP / Perfs / GSC / Kdifficulty **/
  .volume-container,
  .snippets-container,
  .gsc-container,
  .perfs-container,
  .kdifficulty-container
  {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    align-content: flex-start;
    cursor: default;

    &.pointer{
      cursor: pointer;
    }

    h4{
      margin: 0;
    }
    
    hr{
      margin: 0;
    }

    .line-container{
      width: 100%;
      display: flex;
      flex-flow: row;
      
      hr{
        border: none;
        height: 3px;
      }
      .none{
        display: none;
      }
      .positive-line{
        background-color: #565656;
      }
      .negative-line{
        background-color: #e8e9e9;
      }
    }

    p.value,
    p.variation{
      width: 100%;
      text-align: right;
      margin: 0;      
    }
    p.value{
      font-size: 0.93rem;
      font-weight: bold;
      color: black;
    }
    p.variation,
    .percent-symbol{
      font-size: 0.72rem;
      font-weight: 300;
      color: #9F9F9F;
    }
  }

  .volume-container,
  .snippets-container,
  .perfs-container,
  .gsc-container
  {
    .hover-area{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      cursor: pointer;
    }
  }

  /** Volume **/
  .volume-container{    
    position: relative;

    .tooltip{
      width: 250px;
      height: 120px;
      position: absolute;
      z-index: 1;
      
      .loader-container{
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }

  /** Indentifier Date */
  .identifier-date-container{
    font-size: 0.9rem;
    
    p{
      margin: 0;
    }
  }

  /** Snippets **/
  .serp-viewer-container{
    .serp-viewer{
      outline:0;
    }
  }

  /** Snippets & Perfs **/
  .snippets-container,
  .perfs-container{
    position: relative;

    .data-container{
      width: 100%;
      min-height: 30px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    
    .types-snippets{
      width: 100%;
      margin: 8px 0 0 0;
      padding: 0;
      
      li{
        display: inline-block;
        padding-right: 5px;

        span{
          font-size: 0.78rem;
          color: #9F9F9F;
          margin-left: 2px;
        }
      }
    }

    .tooltip{
      width: 500px;
      min-height: 200px;
      padding-right: 10px;
      position: absolute;
      z-index: 1;

      .loader-container{
        position: absolute;
        width: 100%;
        height: 100%;
        
        .loader{
          background-color: #f0f0f0;
        }
      }

      .tooltip-container
      {
        width: 100%;
        height: 300px;
        overflow-y: scroll;
        
        table
        {
          width: 99%;
          border: none;
          background-color: transparent;          

          thead{   
            tr{
              th{
                text-transform: uppercase;
                font-weight: bold;
                padding: 5px 10px 5px 0;
                border-bottom: solid 0.5px black;
  
                h4{
                  display: flex;
                  flex-flow: row;
                  align-items: center;
                  text-align: left;
                  white-space: nowrap;
                  font-size: 1rem;
                  text-transform: uppercase;                
                  margin-right: 5px;
        
                  svg{
                    margin-right: 10px;            
                  }
                }
              };
            }            
          }

          tbody{
            background-color: transparent;

            tr{
              height: 30px;
              line-height: 30px;

              &:nth-child( even ) {
                background: #e5e0df;    
              }
              
              td{
                border: none;
                padding: 0;
                white-space: nowrap;
                vertical-align: middle;

                a{
                  display: block;
                  max-width: 380px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &:hover{
                    text-decoration: underline;
                  }                  
                }

                &.type-snippet{
                  width: 1%;
                  padding: 0 5px;
                  font-weight: bold;  

                  svg{
                    margin-right: 5px;
                  }
                }
              }
            }
          } 
        }        
      }
    }
  }

  /** Perfs **/
  .perfs-container{ 
    .data-container
    {
      .area{
        margin: 0 ;
        text-align: left;
        font-size: 0.78rem;
        font-weight: 400;
        color: #9F9F9F;
      }

      ul.positions{
        margin: 0;
        padding: 0;
        font-size: 0.93rem;
        font-weight: bold;

        li{
          padding: 3px 3px;
          height: 10px;
          line-height: 10px;

          &:first-child{
            border-left: none;
          }

          .etc-symbol{
            font-weight: 300;
            color: #9F9F9F;
            border-left: none;
            padding: 0;
          }
          
          .compare-positions{
            font-weight: 300;
            color: #9F9F9F;
            margin-left: 2px;
          }

          .current-positions.nomatch{
            color: #e43e21;
          }
        }
      }
    }

    .tooltip
    {
      width: 700px;
      z-index: 2;

      &::after{
        left: 55%;
      }

      .tooltip-container
      {
        table
        {
          tbody
          {
            tr{
              button.internal{
                font-weight: 300;
                text-align: left;
                white-space: nowrap;                   
                overflow: hidden;
                text-overflow: ellipsis;  

                &:hover{
                  text-decoration: underline;
                }
              }

              a.external{
                width: 15px;
                margin: 0 10px;
              }

              .perf-positions-trend{
                vertical-align: middle;
                width: 200px;
                height: 70px;
              }
            }
          }

          &.expected-url
          {
            tbody
            {
              margin: 5px 0;

              .link{
                max-width: 600px;
                white-space: nowrap;                   
                overflow: hidden;
                text-overflow: ellipsis;  
    
                span{                
                  font-weight: 500;
                }
              }
              
              button{
                border-radius: 5px;
                border: solid 1px white;
                background-color: #e43e21;
                color: white;
                padding: 2px 5px;
                margin: 0 0 0 10px;
              }
            }
          }

          &.seo-part
          {
            .nomatch{
              color: #e43e21;
            }

            button.internal{
              max-width: 380px;
            }
          }

          &.other-part
          {
            a.external{
              width: 15px;
              margin: 0 0 0 10px;
            }

            button.internal{
              max-width: 500px;
            }
          }
        }
      }
    }
  }

  /** GSC **/
  .gsc-container{
    position: relative;

    .tooltip{
      width: 800px;
      min-height: 200px;
      position: absolute;
      z-index: 1;

      .loader-container{
        position: absolute;
        width: 100%;
        height: 100%;

        .loader{
          background-color: #f0f0f0;
        }
      }

      .tooltip-container
      {
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        
        table{
          width: 780px;
          text-align: center;
          border: none;
          background-color: transparent;
          margin: 0;

          thead{            
            th{
              text-transform: uppercase;
              font-weight: bold;
              padding: 5px 10px;              
              border-bottom: solid 1px;

              button{
                text-transform: uppercase;
                font-weight: bold;  
                display: flex;
                flex-flow: row;
                align-items: center;
                margin: 0 auto;
              }
            };
          }

          tbody{
            background-color: transparent;

            tr{
              height: 30px;
              line-height: 30px;
              
              td{
                border: none;
                padding: 0 10px;
                white-space: nowrap;

                span{
                  font-size: 0.72rem;
                }

                &.url{
                  display: flex;
                  flex-flow: row;
                  justify-content: space-between;
                  height: 30px;

                  button, p{
                    font-weight: 300;
                    text-align: left;
                    max-width: 400px;
                    margin: 0;
                    white-space: nowrap;                   
                    overflow: hidden;
                    text-overflow: ellipsis;  
                  }
                  button.followed{
                    color: #71B8F2;
                    font-weight: 400;
                  }
                  button:hover{
                    text-decoration: underline;
                  }
                }
              }
            }
            tr:nth-child( even ) {
              background: #e5e0df
            }       
          }
          
          td.url,
          th.url{
            text-align: left;
          }
        }
      }      
    }
  }

  /** Actions **/
  .action-container{
    position: relative;
    display: flex;
    flex-flow: column;
    gap: 5px 0;

    .loader-container{
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;

      .indicator{
        margin: 2px 0 0 0;
        height: 17px;
      }

      .loader{
        left: auto;
        width: 100%;        
        border-radius: 15px 15px;
      }
    }
    
    .comment-selector{
      width: 28px;
      height: 10px;
      background-color: #d8d8d8;
      border-radius: 5px 5px;
      stroke: #979797;
      fill: #979797;
      cursor: pointer;

      &.selected{
        background-color: #e43e21;
        stroke: white;
        fill: white;
      }
    }    

    .expectedUrl-selector
    {
      display: block;
      max-width: 130px;
      white-space: nowrap;                   
      overflow: hidden;
      text-overflow: ellipsis;  
      direction: rtl;
      color: #e43e21;
      font-size: 0.8rem;
      font-weight: bold;      
      
      &:hover{
        text-decoration: underline;
      }

      &.off{
        color: #979797;
      }
    }

    .react-select{
      width: 100%;

      .react-select__value-container{
        height: 17px;
        display: flex;
        flex-flow: row;

        .react-select__option{
          width: 100%;
          display: flex;
          flex-flow: row;
          align-items: center;
        }

        .react-select__input-container{
          width: 0;
        }

        .picto{
          display: flex;
          flex-flow: row;
          align-items: center;
        }
      }

      .react-select__option{
        display: flex;
        flex-flow: row;
        align-items: center;

        svg{
          margin-left: 2px;
        }
      }
    }

    &.pointer{
      cursor: pointer;
    }

    .tooltip{
      width: 300px;
      position: absolute;
      z-index: 1;
      
      .tooltip-container{
        width: 100%;
        height: auto;
        overflow: hidden;

        h4{
          width: 95%;
          height: 20px;
          text-align: left;
          text-transform: uppercase;
          border-bottom: solid 0.5px black;
        }

        .loader-container{
          position: absolute;
          width: 100%;
          height: 100%;

          .loader{
            background-color: #f0f0f0;
          }
        }

        .alert{
          position: absolute;
          left: 0;
          top: 0;
          padding: 50px 5px 0 5px;
          width: 333px;
          height: 266px;
          border-radius: 5px;
          background-color: #e43e21;
          color: white;
          text-align: center;
          font-weight: bold;
        }
      }

      .tooltip-container.history
      {
        ul{
          padding: 0;
          margin: 0 10px 0 0;
          text-align: left;

          li{
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: flex-start;            
            padding: 2px 0;  
            font-weight: 300;
          }
        }
      }

      .tooltip-container.comment
      {
        height: 300px;
        overflow-y: scroll;
        text-align: left;
        font-weight: 300;

        .publish-container
        {
          margin-bottom: 20px;

          .newcontent{
            width: 93%;
            height: 60px;
          }
  
          .publishcontent{
            border: solid 1px white;
            background-color: #e43e21;
            border-radius: 5px;
            color: white;
            padding: 5px;
          }
        }

        .comment-container
        {
          margin-bottom: 12px;
          cursor: auto;

          .who
          {
            margin: 0 0 5px 0;
          } 
          
          .content
          {
            width: 100%; 
            display: flex;
            flex-flow: row wrap;           
            align-items: center;

            button{
              margin-left: 5px;
            }

            p{
              background-color: white;
              padding: 5px;
              margin: 0;
              width: 85%;
            }
          }
        }
      }

      .tooltip-container.expectedUrl
      {
        text-align: left;
        font-weight: 400;                
        
        h4{
          width: 100%;
          font-size: 0.85rem;
          font-family: 'PlayfairDisplay';
          border-bottom: solid 1px white;
        }

        a{
          color: white;
          font-weight: 300;

          &:hover{
            text-decoration: underline;
          }
        }

        .loader-container{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          
          .loader{
            border-radius: 5px;
          }
        }

        .options-container
        {
          display: flex;
          flex-flow: column;
          gap: 10px 0;
        }        

        input[type="text"]{
          width: 286px;
          height: 30px;
          border-radius: 5px;
          border: solid 1px white;
          margin-top: 5px;
          padding-left: 10px;
          font-size: 1rem;
        }

        .react-autosuggest__container{
          position: relative;
          font-size: 1rem;
          font-weight: 300;
          text-align: left;
        }
        .react-autosuggest__suggestions-container--open{
          width: 280px;
          margin-top: -5px;
          padding: 0 10px;
          background: white;
          color: #a6a6a6;
          border-radius: 0 0 5px 5px;
          border-top: solid 1px #e43e21;
          
          .react-autosuggest__suggestions-list
          {
            padding: 0;
            margin: 0;
    
            .highlight{
              color: black;
              font-weight: bold;
            }
            
            li{
              display: block;
              width: 280px;
              height: 25px;
              padding: 2px 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              direction: rtl;
              cursor: pointer;
    
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }

        .confirm-container{
          border: none;
        }
      }
    }
  }
  /***************/
  /** End Cells **/
  /***************/
  
  /** Cols **/
  .col-padding-left{
    padding-left: 10px;
  }
  .col-center{
    text-align: center;

    .column-header-title{
      justify-content: center;
    }
  }
  .col-decisions{
    vertical-align: middle;
    padding-top: 0;
  }
  
  /**************************/
  /** Start Header Filters **/
  /**************************/
  .filter-container{
    display: flex;
    flex-flow: row;
    gap: 20px;
    justify-content: space-between;

    .sort,
    .filters,
    .url{
      position: relative;
      width: auto;
      color: white;
      
      .title{
        text-align: left;
        font-family: 'PlayfairDisplay';
        white-space: pre-line;
        
        h4{
          margin: 10px 0;
        }        
      }

      h5{
        font-size: 0.93rem;
        font-weight: 500;
        text-transform: uppercase;
        text-align: left;
        margin: 5px 0 5px 10px;
      }
    }

    .filters,
    .url{
      display: flex;
      flex-flow: column;
    }

    .sort{
      width: 230px;

      .preselect{
        svg{
          margin-right: 10px;
        }
        &.reverse{
          svg{
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }

      .sort-type{
        border-bottom: solid 0.5px white;
        padding-bottom: 10px;
      }
    }

    .filters
    {
      .filters-container{
        max-height: 650px;
        display: flex;
        flex-flow: column wrap;
      }  
    }

    .url{
      .confirm-container{
        justify-content: center;

        button{
          width: 160px;
        }
      }
    }

    .filter{
      .preselect{
        button{
          font-weight: 300;
        }
      }
    }
    
    .loader-container{
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &.perf{
      .filters{
        width: 460px;

        .select{
          width: 200px;
        }
      }
    }

    &.decisions{
      .filters{
        width: 170px;

        .select{
          width: 170px;
        }
      }
    }
  }

  .filter,
  .sort{
    .preselect{
      button{
        width: 100%;        
        white-space: nowrap;
      }
    }
  }

  .filter.text{
    width: 335px;
    display: flex;
    flex-flow: column wrap;

    .options-container{
      position: relative;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;

      label{
        margin: 5px 10px 5px 0;
      }
    }

    input[type="text"]{
      width: 320px;
      height: 30px;
      border-radius: 5px;
      border: solid 1px white;
      margin-top: 5px;
      padding-left: 10px;      
    }

    .react-autosuggest__container{
      position: relative;
      font-size: 1rem;
      text-align: left;
    }
    .react-autosuggest__suggestions-container--open{
      width: 314px;
      margin-top: -5px;
      padding: 0 10px;
      background: white;
      color: #a6a6a6;
      border-radius: 0 0 5px 5px;
      border-top: solid 1px #e43e21;
      
      .react-autosuggest__suggestions-list
      {
        padding-top: 5px;

        .highlight{
          color: black;
          font-weight: bold;
        }
        
        li{
          display: block;
          width: 314px;
          height: 25px;
          padding: 2px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    
    .preselect 
    {
      margin-top: 10px;
    }

    .confirm{
      margin-right: 0;
    }
  } 

  .filter.range{
    display: flex;
    flex-flow: column wrap;
    width: auto;
    color: white;    

    .input-container{
      display: flex;
      flex-flow: row;
      font-weight: 400;
      font-size: 1rem;
      margin: 0 0 15px 10px;    

      .first-input-container,
      .second-input-container{
        display: flex;
        flex-flow: column wrap;        
      }
      .second-input-container{
        margin-left: 5px;
      }

      .confirm-container{
        width: 40px;
        height: 27px;
        border-top: none;
        margin-top: 22px;
        padding-top: 0;
      }
    }

    .confirm-container{
      margin-top: 0;
      
      .reset{
        width: auto;
      }
    }
    
    label{
      text-align: left;
    }

    input{
      width: 60px;
      height: 27px;
      border: none;
      border-radius: 5px;
      padding-left: 10px;
      margin-top: 4px;
      font-weight: 200;
    }

    hr{
      height: 1px;
      width: 190px;
      background-color: rgba( 255, 255, 255, 0.50);
      border: none;
    }
  }

  .filter.range-date
  {
    .date-select{
      select{
        width: 100px;
        height: 27px;
        border-radius: 5px;
        border: none;

        &:first-child{
          margin-right: 7px;
        }
      }
    }
  }

  .filter.select.action button,
  .filter.select.intention button,
  .filter.select.pageType button{
    text-transform: uppercase;
    font-weight: 400;
  }

  .filter.select.intention{
    width: 230px;
  }

  .confirm-container{
    width: 100%;
    border-top: solid 0.5px white;
    padding-top: 10px;    
  }

  /**************************/
  /** End Header Filters **/
  /**************************/
  
  /** Pagination **/
  .pagination-container{
    height: 30px;
    margin: 5px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    button{
      border: solid 1px black;
      border-radius: 2px;
      padding: 0 5px;
      margin: 0 5px;
    }
  }
}